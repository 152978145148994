<template lang="pug">
  div#app
    router-view(name="header")
    main
      //fade-transition(origin="center" mode="out-in" :duration="250")
      router-view
    router-view(name="footer")
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition
  }
};
</script>